<template>
  <VContainer class="pa-0 pt-6">
    <VRow
      align="center"
    >
      <VCol
        class="relative"
        cols="auto"
      >
        <VImg
          v-if="levelsCompleted"
          class="profile__crown"
          :width="32"
          :height="32"
          :src="require('@/assets/crown.png')"
          data-test="profile-about-img"
          data-test-value=""
        />
        <TTAvatar
          x-large
          :text="avatarInitials"
          :src="photoUrl"
          data-test-label="profile-about-avatar"
        />
      </VCol>
      <VCol class="profile__info">
        <VRow
          no-gutters
        >
          <VCol cols="12">
            <div
              data-test="profile-about-name"
              class="tt-text-headline-2"
            >
              {{ firstName }} {{ lastName }}
            </div>
          </VCol>
          <VCol cols="12">
            <div
              v-if="position"
              class="tt-text-body-2"
              data-test="profile-about-position"
            >
              {{ position }}
            </div>
          </VCol>
          <VCol
            v-if="!useNewProfile"
            cols="12"
          >
            <div
              data-test="profile-about-bonus"
              class="tt-text-body-2 tt-light-mono-46--text"
            >
              <span>
                {{ score }} {{ scoreTranslate }}
              </span>
              <span v-if="level"> • {{ level }}</span>
            </div>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import generateUserAvatar from '@/helpers/user';

export default {
  name: 'ProfileHeader',
  inject: ['glFeatures'],
  props: {
    levelsCompleted: {
      type: Boolean,
      default: false,
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    photoUrl: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: null,
    },
    level: {
      type: String,
      default: null,
    },
    score: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    avatarInitials() {
      return generateUserAvatar(this.firstName, this.lastName, this.photoUrl);
    },
    scoreTranslate() {
      return this.$te('server_score_name')
        ? this.$tc('server_score_name', this.score) : this.$tc('pluralize.profile.bonus', this.score);
    },
    useNewProfile() {
      return this.glFeatures('new_profile');
    },
  },
};
</script>

<style scoped lang="scss">
.profile {
  &__name {
    font-size: 32px;
    font-weight: 500;
  }

  &__crown {
    position: absolute;
    z-index: 4;
    left: 52px;
    top: -10px;
  }
}

</style>
