<template>
  <VRow class="ma-0 rating">
    <VCol
      class="pa-0"
      :cols="12"
    >
      <h2 class="tt-text-title-2">
        {{ $t('profile.rating') }}
      </h2>
      <span
        data-test="profile-rating-level"
        class="tt-text-caption mt-1"
      >
        {{ levelName }}
      </span>
      <div class="rating-list d-flex flex-column mt-2">
        <div
          v-for="(item, i) in transformedItems"
          :key="item.staff.firstName + i"
        >
          <RouterLink
            :class="{'my-rating-item' : item.staff.id === meta.id}"
            class="rating-item pr-5 d-flex align-center tt-light-mono-100--text"
            :to="{name : Names.R_APP_PROFILE, params : {userId : String(item.staff.id)}}"
            :data-test="`profile-rating-link-${i}`"
          >
            <span
              data-test="profile-rating-link-number"
              class="tt-text-title-2 ml-6"
            >{{ i + 1 }}</span>
            <TTAvatar
              class="ml-4"
              :text="getAvatarInitials(item)"
              :src="item.staff.photoUrl"
              data-test-label="profile-rating-link-avatar"
            />
            <span
              data-test="profile-rating-link-name"
              class="rating-item-name tt-text-body-1 ml-4"
            >
              {{ item.staff.firstName }} {{ item.staff.lastName }}
            </span>
            <span
              data-test="profile-rating-link-bonus"
              class="tt-text-body-1 tt-light-mono-64--text ml-auto"
            >{{ item.bonus }}</span>
          </RouterLink>
          <VDivider v-if="item.staff.id !== meta.id" />
        </div>
      </div>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'ProfileRating',

  inject: ['Names'],
  props: {
    meta: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: null,
    },
  },
  computed: {
    levelName() {
      if (this.level) {
        return `${this.$tc('names.levels', 0)} «${this.level}»`;
      }
      return '';
    },
    transformedItems() {
      return this.meta.ratings?.reduce((acc, item) => {
        if (acc.length < 10) {
          item.staff.forEach((staff) => {
            if (acc.length < 10) {
              acc.push({
                ...item,
                staff,
              });
            }
          });
        }
        return acc;
      }, []);
    },
  },
  methods: {
    getAvatarInitials(item) {
      return !item.staff.photoUrl ? (item.staff.firstName[0] + item.staff.lastName[0]) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-item {
  height: 71px;
  text-decoration: none;
  margin-top: -1px;
}
.my-rating-item {
  background: map-get($tt-light-mono-0, 'base') !important;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
  border-radius: 8px;
}
.rating-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
