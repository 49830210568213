<template>
  <VRow class="profile__about about">
    <VCol>
      <div class="tt-text-title-2 mb-4 d-flex justify-space-between align-center">
        <span class="about__header">{{ $t('common.about_me') }}</span>
        <TTBtn
          v-if="!aboutTextEdited && !isAnotherProfile"
          color="tt-ghost"
          small
          plain
          data-test-label="profile-about-btn-edit"
          @click="aboutTextEdited=true"
        >
          <VIcon>
            far fa-pen
          </VIcon>
        </TTBtn>
      </div>
      <template v-if="!aboutTextEdited">
        <span
          data-test-label="profile-about-about"
          class="tt-text-body-2 about__text"
        >
          {{ meta.about }}
        </span>
      </template>
      <template v-else>
        <VRow>
          <VCol>
            <VForm
              ref="form"
              v-model="formValid"
            >
              <TTTextarea
                v-model="lazyAbout"
                :maxlength="false"
                :rules="rules"
                auto-grow
                data-test-label="profile-about-textarea"
              />
            </VForm>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <TTBtn
              large
              :block="isMobile"
              :disabled="!formValid"
              data-test-label="profile-about-btn-update"
              @click="updateAbout"
            >
              {{ $t('common.save') }}
            </TTBtn>
          </VCol>
        </VRow>
      </template>
    </VCol>
  </VRow>
</template>

<script>
import {
  V_CHAR,
} from '@/helpers/constants';

export default {
  name: 'ProfileAbout',
  props: {
    /**
     * @type {{
     * firstName: string,
     * lastName: string,
     * about: string,
     * bonus: number,
     * photoUrl: string,
     * staffPosition: string,
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
    isAnotherProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lazyAbout: '',
      aboutTextEdited: false,
      formValid: true,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    rules() {
      return [(v) => !!v || this.$t('validators.info_about_me'), (v) => v.length <= 1000
        || `${this.$t('validators.info_about_me_length_oversize')} ${this.$tc(V_CHAR, 1000)}`];
    },
    avatarInitials() {
      return !this.meta.photoUrl ? (this.meta.firstName[0] + this.meta.lastName[0]) : '';
    },
  },
  watch: {
    'meta.about': {
      immediate: true,
      handler(value) {
        this.lazyAbout = value;
      },
    },
  },
  methods: {
    updateAbout() {
      this.$emit('update:about', this.lazyAbout);
      this.aboutTextEdited = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  &__header {
    font-size: 20px;
  }

  &__text {
    white-space: pre-line;
  }
}

</style>
