<template>
  <TTBtn
    icon
    data-test-label="button-backward"
    @click="$router.go(-1)"
  >
    <VIcon color="tt-light-mono-4">
      fas fa-arrow-left
    </VIcon>
  </TTBtn>
</template>

<script>
export default {
  name: 'BackButton',
};
</script>

<style scoped>

</style>
